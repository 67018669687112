import { useRuntimeConfig } from '#imports';
import useLocalStorageModules from '@/composables/useLocalStorageModules';
export default async () => {
  const appCurrentVersion = await useLocalStorageModules('appVersion');
  const runtimeConfig = useRuntimeConfig();
  if (runtimeConfig.public && (appCurrentVersion !== runtimeConfig.public.appVersion || !appCurrentVersion)) {
    localStorage.clear();
    useLocalStorageModules('appVersion', runtimeConfig.public.appVersion);
  }
};
