<template>
  <div class="flex flex-row min-h-screen">
    <LoginTheLeftLogoArea></LoginTheLeftLogoArea>
    <div class="w-screen md:w-2/5 relative">
      <LanguageLangSwitcher />

      <LoginTheLoginForm :user="`user`" class="h-[calc(100%_-_5rem)] px-16"></LoginTheLoginForm>
    </div>
    <Teleport to="body">
      <LoadersClearLoader v-model="clearObj.pending"></LoadersClearLoader>
    </Teleport>
  </div>
</template>
<script setup>
import useForgotPasswordStore from '@/stores/useForgotPasswordStore';
import { definePageMeta, onBeforeMount, onMounted, useCookie, useNuxtApp, useRoute } from '#imports';
import useClearFetchStore from '@/stores/useClearFetchStore';
import activateStore from '@/stores/useActivateUserStore';
import useClearAppCache from '@/composables/useClearAppCache';
const route = useRoute();
const query = route.query;
const forgotPassObj = useForgotPasswordStore();
const clearObj = useClearFetchStore();
const auth0ErrMsg = useCookie('auth0ErrMsg');
const activateObj = activateStore();
const shouldActivate = useCookie('shouldActivate');

forgotPassObj.resetState();
definePageMeta({
  layout: false,
  head: {
    title: 'ClearPath : Login'
  }
});
onBeforeMount(() => {
  if (query.code && query.error) {
    useNuxtApp().$toast.error(query.error);
  }
  if (auth0ErrMsg.value) {
    useNuxtApp().$toast.error(auth0ErrMsg.value);
  }
});

onMounted(() => {
  auth0ErrMsg.value = '';
  shouldActivate.value = '';
  activateObj.clearResetToken();
  useClearAppCache();
});
</script>
